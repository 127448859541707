<template>
  <Highcharts
    :key="mergedOptions.series"
    ref="highchartsRef"
    class="highchart"
    :options="mergedOptions"
  />
</template>

<script>
import { ref, computed, defineComponent } from 'vue';
import { merge } from 'lodash';

export default defineComponent({
  name: 'HorizontalBar',
  props: {
    chartOptions: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const highchartsRef = ref(null);

    const baseOptions = {
      chart: {
        styledMode: true,
        height: 60,
        type: 'bar',
        reflow: true,
        marginTop: -10,
        spacingTop: 0,
        spacingRight: 0,
        marginRight: 0,
        spacingLeft: 0,
        marginLeft: 0,
        spacingBottom: 0,
        plotBorderWidth: 0,
        backgroundColor: 'transparent',
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        visible: false,
        minPadding: 0,
        maxPadding: 0,
      },
      yAxis: {
        visible: false,
        endOnTick: false,
        reversedStacks: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
      },
      tooltip: {
        useHtml: true,
        shadow: false,
        borderRadius: 9,
      },
    };

    const mergedOptions = computed(() =>
      merge(baseOptions, props.chartOptions)
    );

    return {
      highchartsRef,
      mergedOptions,
    };
  },
});
</script>
<style lang="postcss" scoped>
.highchart {
  @apply h-10 -mb-1;
}
</style>
