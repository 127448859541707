<template>
  <div class="highcharts-container">
    <Highcharts
      ref="highchartsRef"
      class="highchart"
      :options="mergedOptions"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import { merge } from 'lodash';

export default {
  name: 'ScatterPlot',
  props: {
    chartOptions: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const highchartsRef = ref(null);

    const baseOptions = {
      chart: {
        styledMode: true,
        type: 'scatter',
        zoomType: 'xy',
      },
      credits: {
        enabled: false,
      },
      title: null,
      legend: {
        enabled: false,
      },
      tooltip: {
        useHtml: true,
        shadow: false,
        borderRadius: 9,
      },
    };

    const mergedOptions = merge(baseOptions, props.chartOptions);

    return {
      highchartsRef,
      mergedOptions,
    };
  },
};
</script>
<style lang="postcss" scoped>
.highchart {
  @apply -mt-1 -mb-1;
}
</style>
